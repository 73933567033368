
export default {
  beforeRouteLeave (to, from, next) {
    this.resetColors()
    if (this.loop !== null) {
      clearInterval(this.loop)
    }
    next()
  },
  data () {
    return {
      loop: null,
      marquee: 'PAGE NOT FOUND · PAGINA NON TROVATA',
      iFrame: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJWLW5T" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: 'error'
      },
      htmlAttrs: {
        class: 'htmlError'
      },
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },
  computed: {
    colors () {
      return this.$store.state.colors
    },
    classes () {
      return this.$store.state.classes
    },
    isAnim () {
      return this.$store.state.isAnim
    },
    layout () {
      return this.$store.state.headerLayout
    }
  },
  beforeDestroy () {
    this.resetColors()
    if (this.loop !== null) {
      clearInterval(this.loop)
    }
    this.resetColors()
  },
  // mounted () {
  //   const _this = this
  //   this.$store.commit('setPostsByCat', false)
  //   this.$nextTick(function () {
  //     _this.initSpaceInvaders()
  //     if (!this.isAnim) {
  //       const _this = this
  //       setTimeout(function () {
  //         _this.setColors()
  //         _this.loopColors()
  //       }, 1000)
  //     }
  //   })
  // },
  mounted () {
    const _this = this
    this.$store.commit('setPostsByCat', false)
    this.$nextTick(function () {
      if (!this.isAnim) {
        this.resetColors()

        setTimeout(function () {
          _this.setColors()
          _this.loopColors()
        }, 1000)
      }
    })
  },
  methods: {
    initSpaceInvaders () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '/spaceInvaders.js'
      document.body.appendChild(script)
    },
    setColors () {
      this.resetColors()
      const boxes = document.querySelectorAll('.box:not(.not)').length
      const tmp = []
      let pos = null
      if (this.resetted) {
        for (let i = 0; i < this.colors.length; i++) {
          if (i < boxes) {
            do {
              pos = Math.floor(Math.random() * boxes)
            } while (tmp.includes(pos))
            tmp.push(pos)
            const box = document.querySelectorAll('.box:not(.not)')[pos]
            if (box !== undefined && box !== null) {
              box.classList.add(this.colors[i])
              box.classList.add(this.classes[i][0])
            }
          }
        }
        const _colors = this.colors
        const _classes = this.classes
        setTimeout(function () {
          for (let z = 0; z < _colors.length; z++) {
            const box2 = document.querySelectorAll('.box:not(.not)')[tmp[z]]
            if (box2 !== undefined && box2 !== null) {
              box2.classList.remove(_classes[z][0])
              const c = Math.floor(Math.random() * _colors.length)
              box2.classList.add(_classes[c][1])
            }
          }
        }, 2500)
      }
    },
    resetColors () {
      const boxes = document.querySelectorAll('.box:not(.not)')
      for (let i = 0; i < boxes.length; i++) {
        for (let z = 0; z < this.classes.length; z++) {
          boxes[i].classList.remove(this.classes[z][1])
          boxes[i].classList.remove(this.classes[z][0])
        }
        for (let z = 0; z < this.colors.length; z++) {
          boxes[i].classList.remove(this.colors[z])
        }
        if (i === boxes.length - 1) {
          this.resetted = true
        }
      }
    },
    loopColors () {
      this.loop = setInterval(() => {
        this.setColors()
      }, 3500)
    }
  }
}
