import Vue from 'vue'

class VueIubenda {
  static install (_Vue, options) {
    /* eslint-disable-next-line */
    window._iub = window._iub || {};
    /* eslint-disable-next-line */
    _iub.csLoaded = false;
    /* eslint-disable-next-line */
    _iub.csConfiguration = options;
    const iubenda = document.createElement('script')
    iubenda.setAttribute('type', 'text/javascript')
    iubenda.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js')
    iubenda.setAttribute('charset', 'utf-8')
    iubenda.setAttribute('async', 'true')
    document.head.appendChild(iubenda)
  }
}

Vue.use(VueIubenda, {
  consentOnContinuedBrowsing: false,
  countryDetection: true,
  gdprAppliesGlobally: false,
  invalidateConsentWithoutLog: true,
  perPurposeConsent: true,
  siteId: 3408736,
  whitelabel: false,
  cookiePolicyId: 69287334,
  lang: 'it',
  banner: {
    acceptButtonCaptionColor: 'white',
    acceptButtonColor: '#010101',
    backgroundColor: '#ffffff',
    customizeButtonCaptionColor: 'white',
    customizeButtonColor: 'rgba(1, 1, 1, 0.4)',
    acceptButtonDisplay: true,
    closeButtonRejects: true,
    customizeButtonDisplay: true,
    explicitWithdrawal: true,
    rejectButtonCaptionColor: 'white',
    rejectButtonColor: '#000000',
    rejectButtonDisplay: true,
    slideDown: true,
    textColor: '#010101',
    listPurposes: true,
    position: 'float-bottom-center'
  }
//   callback: {
//     onPreferenceExpressed (preference) {
//       console.log(window.dataLayer)
//       // eslint-disable-next-line
//     //   gtag('consent', 'update', {
//     //     ad_storage: 'denied',
//     //     analytics_storage: preference.purposes[4] ? 'granted' : 'denied',
//     //     functionality_storage: preference.purposes[2] ? 'granted' : 'denied',
//     //     personalization_storage: preference.purposes[3] ? 'granted' : 'denied',
//     //     security_storage: preference.purposes[2] ? 'granted' : 'denied'
//     //   })
//     //   console.log(window.dataLayer)
//     },
//     onError (error) {
//       console.log(error)
//     }
//   }
})
