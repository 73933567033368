const redirects =
[
  { from: '/sviluppo-web-e-app/', to: '/sviluppo-software-app/' },
  { from: '/grafica-e-brand/', to: '/grafica-brand/' },
  { from: '/comunicazione-marketing/', to: '/web-marketing/' },
  { from: '/fotografia-video-e-3d/', to: '/rendering-3d/' },
  { from: '/project/aeronautica-militare/', to: '/progetti/sito-e-commerce-seo-aeronautica-militare/' },
  { from: '/project/caruso-acoustic/', to: '/progetti/sito-web-caruso-acoustic/' },
  { from: '/project/wheelsbike/', to: '/progetti/e-commerce-wheelsbike-con-rendering-3d/' },
  { from: '/sviluppo-web-e-app/', to: '/sviluppo-software-app/' },
  { from: '/project/edilfloor/', to: '/progetti/sito-web-edilfloor/' },
  { from: '/project/lamm/', to: '/progetti/sito-web-lamm/' },
  { from: '/project/dm-pack/', to: ' /progetti/sito-web-dm-pack/' }
]

module.exports = function (req, res, next) {
  const url = req.url.split('?')[0]
  let urlParams = null
  if (req.url.includes('?')) {
    urlParams = '?' + req.url.split('?')[1]
  }

  const redirect = redirects.find(r => r.from === url)
  if (redirect) {
    let newLocation
    if (urlParams) {
      newLocation = redirect.to + urlParams
    } else {
      newLocation = redirect.to
    }
    res.writeHead(301, {
      Location: newLocation
    })
    res.end()
  } else {
    next()
  }
}
