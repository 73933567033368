

export default {
  beforeRouteLeave (to, from, next) {
    this.resetColors()
    next()
  },
  // eslint-disable-next-line import/no-named-as-default
  /* eslint-disable new-cap */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-undef */
  /* eslint-disable camelcase */
  data () {
    return {
      resetted: false,
      cursorIndex: 0,
      iFrame: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJWLW5T" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    }
  },
  computed: {
    colors () {
      return this.$store.state.colors
    },
    classes () {
      return this.$store.state.classes
    },
    layout () {
      return this.$store.state.headerLayout
    },
    isAnim () {
      return this.$store.state.isAnim
    },
    isServer () {
      return this.$store.state.isServer
    }
  },
  watch: {
    $route (to, from) {
      this.cursorIndex++
      this.resetColors()
      if (to.name === 'progetti-slug' || to.name === 'progetti' || to.name === 'progetti-cliente-slug' || to.name === 'progetti-categoria-slug' || to.name === 'progetti-page-num' || to.name === 'progetti-categoria-slug-page-num') {
        const _this = this
        setTimeout(function () {
          _this.setColors()
        }, 1000)
      }
    }
  },
  created () {
    if (this.$isServer) { this.$store.commit('setIsServer', 1) }
  },
  mounted () {
    const _this = this
    if (!this.isServer) {
      this.$nextTick(function () {
        this.resetColors()
        setTimeout(function () {
          _this.setColors()
        }, 1000)
      })
    }
    if (this.isServer) {
      this.resetColors()
      setTimeout(function () {
        _this.setColors()
      }, 1000)
    }
  },
  methods: {
    setColors () {
      this.resetColors()
      const boxes = document.querySelectorAll('.box:not(.not)').length
      const tmp = []
      let pos = null
      if (this.resetted) {
        for (let i = 0; i < this.colors.length; i++) {
          if (i < boxes) {
            do {
              pos = Math.floor(Math.random() * boxes)
            } while (tmp.includes(pos))
            tmp.push(pos)
            const box = document.querySelectorAll('.box:not(.not)')[pos]
            if (box !== undefined && box !== null) {
              box.classList.add(this.colors[i])
              box.classList.add(this.classes[i][0])
            }
          }
        }
      }
    },
    resetColors () {
      const boxes = document.querySelectorAll('.box:not(.not)')
      if (boxes) {
        for (let i = 0; i < boxes.length; i++) {
          for (let z = 0; z < this.classes.length; z++) {
            boxes[i].classList.remove(this.classes[z][1])
            boxes[i].classList.remove(this.classes[z][0])
          }
          for (let z = 0; z < this.colors.length; z++) {
            boxes[i].classList.remove(this.colors[z])
          }
          if (i === boxes.length - 1) {
            this.resetted = true
          }
        }
      }
    },
    loopColors () {
      if (!this.isAnim && $(document.body).hasClass('home')) {
        setInterval(() => {
          this.setColors()
        }, 3000)
      }
    }
  }
}
