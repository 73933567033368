
export default {
  props: { layout: { type: Number, required: true, default: 0 } },
  data () {
    return {
      drawer: false,
      windowWidth: 1920
    }
  },
  mounted () {
    const _this = this
    this.$nextTick(function () {
      _this.windowWidth = window.innerWidth
      window.addEventListener('resize', _this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    blockScroll () {
      const root = document.getElementsByTagName('html')[0]
      root.setAttribute('class', 'blocked')
    }
  }
}
