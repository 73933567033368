import { render, staticRenderFns } from "./HeaderMobile.vue?vue&type=template&id=5c8b1ecc&scoped=true&"
import script from "./HeaderMobile.vue?vue&type=script&lang=js&"
export * from "./HeaderMobile.vue?vue&type=script&lang=js&"
import style0 from "./HeaderMobile.vue?vue&type=style&index=0&id=5c8b1ecc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8b1ecc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuMobile: require('/builds/studio375/studio375-sito/components/Header/MenuMobile.vue').default,LogoMobile: require('/builds/studio375/studio375-sito/components/UI/LogoMobile.vue').default,LogoStudioInline: require('/builds/studio375/studio375-sito/components/UI/LogoStudioInline.vue').default,Social: require('/builds/studio375/studio375-sito/components/Header/Social.vue').default,Header: require('/builds/studio375/studio375-sito/components/Header/Header.vue').default})
