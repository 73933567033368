
export default {
  props: { layout: { type: Number, required: true, default: 0 } },
  data () {
    return {
      showFilters: false,
      windowWidth: 1920
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    closeSidebar () {
      this.showFilters = false
    },
    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
