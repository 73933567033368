
export default {
  data () {
    return {
      navigation: [
        { label: 'studio', to: '/agenzia-di-comunicazione/', allowClick: true },
        { label: 'servizi', allowClick: false, hasSub: true },
        { label: 'progetti', to: '/progetti/', allowClick: true },
        { label: 'contatti', to: '/contatti/', allowClick: true }
      ]

    }
  },
  computed: {
    categories () {
      return this.$store.state.categories
    }
  }

}
