
export default {
  data () {
    return {
      resetted: false,
      iFrame: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJWLW5T" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    }
  },
  computed: {
    colors () {
      return this.$store.state.colors
    },
    classes () {
      return this.$store.state.classes
    },
    layout () {
      return this.$store.state.headerLayout
    },
    isAnim () {
      return this.$store.state.isAnim
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.commit('setPostsByCat', false)
    })
  },
  methods: {

  }
}
