import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=39fc4da2&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=39fc4da2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39fc4da2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoStudioInline: require('/builds/studio375/studio375-sito/components/UI/LogoStudioInline.vue').default,Menu: require('/builds/studio375/studio375-sito/components/Header/Menu.vue').default,Social: require('/builds/studio375/studio375-sito/components/Header/Social.vue').default,Header: require('/builds/studio375/studio375-sito/components/Header/Header.vue').default})
