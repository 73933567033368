/* eslint-disable no-undef */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-console */
import Vuex from 'vuex'
import { gql } from 'nuxt-graphql-request'
const createStore = () => {
  return new Vuex.Store({
    state: {
      colors: ['bg1', 'bg2', 'bg3', 'bg4'],
      colorsCode: ['#FFE682', '#82FFA8', '#FF7E7E', '#82FBFF'],
      classes: [['anim_in_left', 'anim_out_right'], ['anim_in_right', 'anim_out_left'], ['anim_in_top', 'anim_out_bottom'], ['anim_in_bottom', 'anim_out_top']],
      categories: [],
      settori: [],
      pages: {},
      headerLayout: 0,
      itemsPerPage: 10,
      isAnim: false,
      currentCat: '',
      readyFullpage: false,
      routeChange: false,
      apolloLoading: 0,
      isServer: 0,
      postsCurrentCat: false,
      services: false,
      firstPost: '',
      backProjectsPath: '/progetti',
      postsPath: []

    },
    mutations: {
      setCategories (state, categories) {
        state.categories = categories
      },
      setSettori (state, settori) {
        state.settori = settori
      },
      setPages (state, pages) {
        state.pages = pages
      },
      setHeaderLayout (state, layout) {
        state.headerLayout = layout
      },
      setAnim (state, anim) {
        state.isAnim = anim
      },
      setRoute (state, routeChange) {
        state.routeChange = routeChange
      },
      setCurrentCat (state, slug) {
        state.currentCat = slug
      },
      setApolloLoading (state, loading) {
        state.apolloLoading = loading
      },
      setIsServer (state, server) {
        state.isServer = server
      },
      setPostsByCat (state, postsCurrentCat) {
        state.postsCurrentCat = postsCurrentCat
      },
      setServices (state, services) {
        state.services = services
      },
      setFirstPost (state, firstPost) {
        state.firstPost = firstPost
      },
      setBackProjectsPath (state, backProjectsPath) {
        state.backProjectsPath = backProjectsPath
      },
      setPostsPath (state, postsPath) {
        state.postsPath = postsPath
      }

    },
    actions: {
      async nuxtServerInit (vuexContext, context) {
        // init categories
        const query = gql`
        query MyQuery {
              categories(where: {exclude: "1"}) {
                nodes {
                  databaseId
                  name
                  slug
                }
              }
            }`
        const categories = await this.$graphql.default.request(query)
        vuexContext.commit('setCategories', categories.categories.nodes)

        // init pages
        const query2 = gql`
        query MyQuery {
          pages(first: 9999) {
            nodes {
              databaseId
              slug
              title
              content
              seo {
                metaDesc
                title
              }
            }
          }
        }`
        const pages = await this.$graphql.default.request(query2)
        vuexContext.commit('setPages', pages.pages.nodes)
      }
    },
    getters: {
      getPageBySlug: state => (slug) => {
        if (state.pages !== undefined && state.pages !== null) { return state.pages.filter(page => page.slug === slug) } else { return state.pages }
      },
      getSetNameBySlug: state => (slug) => {
        if (state.settori !== undefined && state.settori !== null) { return state.settori.filter(s => s.slug === slug)[0].name } else { return null }
      },
      getCatNameBySlug: state => (slug) => {
        if (state.categories !== undefined && state.categories !== null) { return state.categories.filter(c => c.slug === slug)[0].name } else { return null }
      },
      getPageByID: state => (id) => {
        if (state.pages !== undefined && state.pages !== null) { return state.pages.filter(page => page.databaseId === id) } else { return state.pages }
      },
      getSeoByID: state => (id) => {
        if (state.pages !== undefined && state.pages !== null) { return state.pages.filter(page => page.databaseId === id)[0].seo } else { return null }
      },
      getSeoBySlug: state => (slug) => {
        if (state.pages !== undefined && state.pages !== null) { return state.pages.filter(page => page.slug === slug)[0].seo } else { return null }
      },
      getPostsPaginated: state => (pageNum) => {
        if (state.posts !== undefined && state.posts !== null) {
          const index = this.currentPage * this.itemsPerPage - this.itemsPerPage
          return state.posts.slice(index, index + this.itemsPerPage)
        } else {
          return null
        }
      }
    }
  })
}

export default createStore
