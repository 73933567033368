
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

if (process.client) {
  gsap.registerPlugin(SplitText)
}

export default {
  props: { text: { type: String, required: true, default: '' } },
  mounted () {
    this.splitMarqueeText()
  },
  methods: {
    splitMarqueeText () {
      const textWhite = '.marquee_white .marquee_inner'
      // eslint-disable-next-line no-unused-vars
      const splitWhite = new SplitText(textWhite, {
        type: 'words,chars',
        charsClass: 'marquee-char',
        wordsClass: 'marquee-word',
        tag: 'span'
      })

      const textBlack = '.marquee_black .marquee_inner'
      // eslint-disable-next-line no-unused-vars
      const splitBlack = new SplitText(textBlack, {
        type: 'words,chars',
        charsClass: 'marquee-char',
        wordsClass: 'marquee-word',
        tag: 'span'
      })

      const speed = this.text.length / 1.25
      document.getElementById('uno').style.animationDuration = speed + 's'
      document.getElementById('due').style.animationDuration = speed + 's'
      document.getElementById('tre').style.animationDuration = speed + 's'
      document.getElementById('quattro').style.animationDuration = speed + 's'
      document.getElementById('cinque').style.animationDuration = speed + 's'
      document.getElementById('sei').style.animationDuration = speed + 's'
    }
  }

}
