
import { gsap } from 'gsap'

export default {
  props: { drawer: { type: Boolean, required: true, default: false }, social: { type: Boolean, required: true, default: false } },
  data () {
    return {
      group: null,
      submenuToggle: undefined,
      navigation: [
        { label: 'studio', to: '/agenzia-di-comunicazione/', allowClick: true },
        { label: 'servizi', allowClick: false, hasSub: true },
        { label: 'progetti', to: '/progetti/', allowClick: true },
        { label: 'contatti', to: '/contatti/', allowClick: true }
      ],
      animationIn: null
    }
  },
  computed: {
    localDrawer () {
      return this.drawer
    },
    categories () {
      return this.$store.state.categories
    }
  },
  watch: {
    group () {
      this.localDrawer = false
    }
  },
  methods: {
    showSub () {
      // eslint-disable-next-line no-undef
      jQuery('.submenu-item.back-arrow').removeClass('active')
      const primary = '.v-list-item > a,.v-list-item > .menu-item > span,.social .studio_social'
      const secondary = '.submenu-items .submenu-item > a'
      this.animationIn = gsap.timeline()
      this.animationIn.to(primary, 0.3, { translateY: '150%', ease: 'Power0.easeNone' })
        .to(primary, 0, { opacity: '0' })
        // eslint-disable-next-line no-undef
        .add(function () { jQuery('.v-list-item:not(.active)').hide(0) })
        .to('.submenu-items', 0, { visibility: 'visible', height: 'auto' }, '+=0.25')
        .to(secondary, 0.33, { translateY: '0%', ease: 'Power0.easeNone' })
    },
    closeSub () {
      // eslint-disable-next-line no-undef
      if (!jQuery('.submenu-item.back-arrow').hasClass('active')) {
      // eslint-disable-next-line no-undef
        jQuery('.submenu-item.back-arrow').addClass('active')
        const primary = '.v-list-item > a,.v-list-item > .menu-item > span,.social .studio_social'
        const secondary = '.submenu-items .submenu-item > a'
        this.animationIn = gsap.timeline()
        this.animationIn.to(secondary, 0.3, { translateY: '150%', ease: 'Power0.easeNone' })
          .to('.submenu-items', 0, { visibility: 'hidden', height: '0px' })
        // eslint-disable-next-line no-undef
          .add(function () { jQuery('.v-list-item:not(.active)').show(0) })
          .to(primary, 0, { opacity: '1' }, '+=0.25')
          .to(primary, 0.33, { translateY: '0%', ease: 'Power0.easeNone' })
      }
    }
  }

}
