
export default {
  data () {
    return {
      cursor: null,
      cursorCircle: null,
      withHover: []
    }
  },
  watch: {
    '$route.path' () {
      this.destroy()
      this.$nextTick(this.init)
    }
  },
  mounted () {
    this.$nextTick(this.init)
  },
  beforeDestroy () {
    this.destroy()
  },
  methods: {
    init () {
      setTimeout(() => {
        this.cursor = document.getElementById('cursor')
        this.cursorCircle = document.getElementById('cursor__circle')
        this.withHover = [
          ...document.querySelectorAll('[data-cursor]')
        ]

        const mouse = { x: -100, y: -100 } // mouse pointer's coordinates
        const pos = { x: 0, y: 0 } // cursor's coordinates
        const speed = 1 // between 0 and 1

        const updateCoordinates = (e) => {
          mouse.x = e.clientX
          mouse.y = e.clientY
          this.cursor.classList.add('active')
        }

        window.addEventListener('mousemove', updateCoordinates)
        window.addEventListener('mousedown', updateCoordinates)

        const _this = this
        function loop () {
          _this.updateCursor(mouse, pos, speed)
          requestAnimationFrame(loop)
        }
        requestAnimationFrame(loop)

        this.withHover.forEach((el) => {
          el.addEventListener('mouseover', this.mouseEnter)
          el.addEventListener('mouseout', this.mouseLeave)
        })
      }, 100)
    },
    destroy () {
      this.withHover.forEach((el) => {
        el.removeEventListener('mouseover', this.mouseEnter)
        el.removeEventListener('mouseout', this.mouseLeave)
      })
    },
    mouseEnter () {
      this.cursor.classList.add('cursor--hover')
    },
    mouseLeave () {
      this.cursor.classList.remove('cursor--hover')
    },
    updateCursor (mouse, pos, speed) {
      const diffX = Math.round(mouse.x - pos.x)
      const diffY = Math.round(mouse.y - pos.y)

      pos.x += diffX * speed
      pos.y += diffY * speed

      const angle = this.getAngle(diffX, diffY)
      const squeeze = this.getSqueeze(diffX, diffY)

      const scale = 'scale(' + (1 + squeeze) + ', ' + (1 - squeeze) + ')'
      const rotate = 'rotate(' + angle + 'deg)'
      const translate = 'translate3d(' + pos.x + 'px ,' + pos.y + 'px, 0)'

      this.cursor.style.transform = translate
      this.cursorCircle.style.transform = rotate + scale
    },
    getAngle (diffX, diffY) {
      return Math.atan2(diffY, diffX) * 180 / Math.PI
    },
    getSqueeze (diffX, diffY) {
      const distance = Math.sqrt(
        Math.pow(diffX, 2) + Math.pow(diffY, 2)
      )
      const maxSqueeze = 0.15
      const accelerator = 1500
      return Math.min(distance / accelerator, maxSqueeze)
    }
  }
}
